import CalendarHeader from '@/Calendar/components/CalendarHeader.vue';
import { getDate, getMonthViewStartDay, getItemStatus, getDateInfo, updateCalendarData, getCurDay } from '@/util/dates';
import { defineComponent, ref, computed, watch } from '@vue/composition-api';
import { DATE_FORMATS } from '@/Calendar/const';
const COL_NUM = 7;
export default defineComponent({
    components: {
        CalendarHeader
    },
    props: {
        prefixClass: {
            type: String,
            default: 'we-calendar'
        },
        startDate: {
            type: [Number, String, Date],
            default: () => new Date()
        },
        dateData: {
            type: [Object, Array],
            default: () => []
        },
        matchKey: {
            type: String,
            default: 'date'
        },
        locale: {
            type: String,
            default: 'en'
        },
        firstDay: {
            type: Number,
            default: 0
        },
        mode: {
            type: String,
            default: 'month',
            validator: val => val === 'month' || val === 'week'
        },
        renderHeader: { type: Function, default: () => { } }
    },
    setup(props, context) {
        const { emit } = context;
        const rowNum = ref(6);
        const currentDay = ref('');
        const today = ref(props.startDate);
        const localeData = computed(() => globalThis.$moment.weekdaysMin());
        const titleArray = computed(() => {
            const arr = localeData.value;
            let i = props.firstDay - 1;
            return arr.map(() => {
                i += 1;
                if (i >= 7) {
                    i = 0;
                }
                return arr[i];
            });
        });
        const userData = computed(() => {
            const result = {};
            if (Array.isArray(props.dateData)) {
                props.dateData.forEach(item => {
                    const date = getDate(item[props.matchKey]);
                    if (result[date]) {
                        result[date].push(item);
                    }
                    else {
                        result[date] = [item];
                    }
                });
            }
            else {
                // object data
                Object.keys(props.dateData).forEach(key => {
                    const date = getDate(key);
                    result[date] = [props.dateData[key]];
                });
            }
            return result;
        });
        const monthData = computed(() => {
            if (!currentDay.value) {
                return [];
            }
            // start date of view, and it will be
            let startDate = getMonthViewStartDay(currentDay.value, props.firstDay, props.mode);
            const monthData = [];
            // loop view item and get date data
            for (let row = 0; row < rowNum.value; row += 1) {
                for (let col = 0; col < COL_NUM; col += 1) {
                    // init array
                    if (!monthData[row])
                        monthData[row] = [];
                    const data = userData[startDate.format(DATE_FORMATS.calendarCore)] || [];
                    monthData[row].push({
                        ...getItemStatus(startDate, data, props),
                        data,
                        date: getDateInfo(startDate)
                    });
                    // increase date
                    startDate = startDate.add(1, 'day');
                }
            }
            return monthData;
        });
        const getEventArgs = () => {
            if (!monthData.value || !monthData.value.length)
                return;
            const startDate = monthData.value?.[0]?.[0]?.date;
            const endDay = monthData.value?.[rowNum.value - 1]?.[COL_NUM - 1]?.date;
            const now = getDateInfo(currentDay.value);
            // TODO: Dirty hack to prevent onMonthChange from being emitted twice when initial date is 1970-01-01
            // This is a temporary fix until we can fix the root cause of the issue
            if (now?.year === 1970)
                return;
            return { startDate, endDay, now };
        };
        const onMonthChange = () => {
            emit('onMonthChange', getEventArgs());
        };
        watch(() => props.mode, () => {
            rowNum.value = props.mode === 'week' ? 1 : 6;
            onMonthChange();
        }, { immediate: true });
        watch(() => currentDay.value, () => {
            if (currentDay.value) {
                onMonthChange();
            }
        }, { immediate: true });
        watch(() => props.startDate, () => {
            currentDay.value = props.startDate
                ? new Date(props.startDate)
                : new Date();
            if (!today.value)
                today.value = currentDay.value;
        }, { immediate: true });
        const changeDate = (date) => {
            if (!date)
                return;
            if (typeof date === 'string') {
                currentDay.value = getCurDay(date);
                emit('navigate');
            }
            else if (typeof date === 'object') {
                currentDay.value = getCurDay(date.full);
                emit('navigate');
            }
            else {
                /* tslint:disable: no-console */
                console.error('invalid date!');
                return;
            }
        };
        const getSelectedDay = (day) => {
            changeDate(day);
        };
        const prev = () => {
            currentDay.value = updateCalendarData(currentDay.value, 'subtract', props);
            emit('prev', getEventArgs());
            emit('navigate');
        };
        const next = () => {
            currentDay.value = updateCalendarData(currentDay.value, 'add', props);
            emit('next', getEventArgs());
            emit('navigate');
        };
        return {
            currentDay,
            monthData,
            titleArray,
            getSelectedDay,
            next,
            prev
        };
    }
});
