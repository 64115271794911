import { defineComponent, ref } from '@vue/composition-api';
import { BookingStatus } from '@/generated-types/graphql.types';
import { isAfterBookBeforeMinimumThresholdMonthView, isBeforeBookBeforeMaximumThresholdMonthView } from '@/util/dates';
import moment from "moment";
export default defineComponent({
    props: {
        data: {
            type: Array,
            default: () => []
        },
        getSelectedDay: {
            type: Function,
            default: () => { }
        },
        identifier: {
            type: String,
            default: ''
        },
        titleArray: {
            type: Array,
            default: () => []
        },
        size: {
            type: String,
            default: 'normal',
            validate: val => val === 'normal' || val === 'small'
        },
        spacesCalendarData: {
            type: Array,
            default: () => []
        },
        isGuestMode: {
            type: Boolean,
            default: false
        },
        allVenueSpaces: {
            type: Array,
            default: () => []
        }
    },
    setup(props, { emit }) {
        const getDayLimits = (fullDate) => {
            let day = props.spacesCalendarData.find(day => day.day === fullDate);
            if (!day) {
                const sameWeekday = moment(fullDate)
                    .subtract(7, 'day')
                    .format('YYYY-MM-DD');
                day = props.spacesCalendarData.find(day => day.day === sameWeekday);
            }
            return {
                hasActiveBookings: (day?.space_bookings &&
                    day.space_bookings.filter(booking => [
                        BookingStatus.Pending,
                        BookingStatus.PaymentProcessing,
                        BookingStatus.Paid
                    ].includes(booking?.booking_status)).length > 0) ||
                    false,
                isFullyClosed: day?.is_day_fully_closed || false,
                isInAnotherMonth: day?.is_in_another_month || false,
                isInCurrentWeek: day?.is_in_current_week || false,
                isNotWorkingDay: !day?.is_venue_working_day || false,
                isPartiallyClosed: (day?.is_day_partially_closed && day?.is_venue_working_day) ||
                    (day?.space_availability?.filter(slot => slot.is_opened)?.length >
                        0 &&
                        !day?.is_venue_working_day) ||
                    false,
                isToday: day?.is_today || false,
                isBookingLimitReached: !props.isGuestMode || !day?.space_bookings?.length
                    ? false
                    : day.space_bookings.length >= day.space_bookings[0].booking_limit,
                isBeforeBookingMinThreshold: !props.isGuestMode
                    ? false
                    : !isAfterBookBeforeMinimumThresholdMonthView(fullDate, props.allVenueSpaces[0].book_before_min_th),
                isBeforeBookingMaxThreshold: !props.isGuestMode
                    ? true
                    : isBeforeBookBeforeMaximumThresholdMonthView(fullDate, props.allVenueSpaces[0].book_before_max_th)
            };
        };
        const onMonthDayClick = () => {
            // When a day is clicked in a Month View, the calendar mode for the space page must be set to `week`
            // See parent `we-calendar.vue`
            emit('month-day-clicked');
        };
        const activeWeekRowIndex = ref(0);
        const toggleActiveRow = (rowIndex) => {
            activeWeekRowIndex.value = rowIndex;
        };
        return {
            activeWeekRowIndex,
            getDayLimits,
            onMonthDayClick,
            toggleActiveRow
        };
    }
});
