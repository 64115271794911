import { getMonthViewStartDay } from '@/util/dates';
import { DATE_FORMATS } from '@/Calendar/const';
import { defineComponent, computed, getCurrentInstance } from '@vue/composition-api';
export default defineComponent({
    props: {
        prefixClass: {
            type: String,
            required: true
        },
        mode: {
            type: String,
            required: true
        },
        firstDay: {
            type: Number,
            required: true
        },
        renderHeader: {
            type: Function,
            required: true
        },
        getSelectedDay: {
            type: Function,
            required: true
        },
        headerLeft: {
            type: [Object, Array],
            default: () => { }
        },
        headerRight: {
            type: [Object, Array],
            default: () => { }
        },
        currentDate: {
            type: [Date, Object, String],
            required: true
        }
    },
    setup(props) {
        const headerDateText = computed(() => {
            const date = globalThis.$moment(props.currentDate);
            if (props.mode === 'week') {
                const startDate = getMonthViewStartDay(props.currentDate, props.firstDay, props.mode);
                const s = startDate.format(DATE_FORMATS.headerTitleWeek);
                const e = startDate.add(6, 'd').format(DATE_FORMATS.headerTitleWeek);
                return { year: date.year(), title: `${s} - ${e}`, mode: props.mode };
            }
            return {
                year: date.year(),
                title: date.format(DATE_FORMATS.headerTitleMonth),
                mode: props.mode
            };
        });
        const HeaderCenter = computed(() => {
            const instance = getCurrentInstance();
            if (!instance)
                return;
            const parent = instance.$parent;
            const { prev, next } = parent;
            return props.renderHeader({
                prev,
                next,
                selectedDate: headerDateText.value,
                getSelectedDay: props.getSelectedDay
            });
        });
        const pre = computed(() => `${props.prefixClass}-header`);
        return { HeaderCenter, pre };
    },
    render() {
        return this.HeaderCenter;
    }
});
