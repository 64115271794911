import Core from '@/Calendar/core.vue';
import MonthView from '@/Calendar/components/MonthView.vue';
import WeekView from '@/Calendar/components/WeekView.vue';
import MonthSelectPopup from '@/Calendar/components/MonthSelectPopup.vue';
import { unixFormatted } from '@/util/dates';
import { defineComponent, ref, computed, watch, createElement } from '@vue/composition-api';
export default defineComponent({
    components: {
        Core,
        MonthView,
        WeekView,
        MonthSelectPopup
    },
    props: {
        weekTimeline: {
            type: Object,
            default: () => ({})
        },
        allVenueSpaces: {
            type: Array,
            default: () => []
        },
        isGuestMode: {
            type: Boolean,
            default: false
        },
        dateData: {
            type: [Array, Object],
            default: () => []
        },
        slots: {
            type: Object,
            default: () => { }
        },
        workingTime: {
            type: Array,
            default: () => []
        },
        fromToLabels: {
            type: Array,
            default: () => []
        },
        mode: {
            type: String,
            default: 'month'
        },
        showTitle: Boolean,
        title: {
            type: Object,
            default: () => ({
                controls: null,
                left: 'Title'
            })
        },
        size: { type: [String], default: 'normal' },
        startDate: {
            type: [Date, String],
            default: null
        },
        wtMode: {
            type: Boolean,
            default: false
        },
        spacesCalendarData: {
            type: Array,
            default: () => []
        }
    },
    setup(props, context) {
        const { emit } = context;
        const identifier = ref('');
        const transitionName = 'fade';
        const month = MonthView;
        const popup = MonthSelectPopup;
        const week = WeekView;
        const getComponentMode = computed(() => props.mode === 'month' ? month : week);
        watch(() => identifier.value, () => {
            if (props.slots && props.slots.slotFrom) {
                emit('identifier', unixFormatted(props.slots.slotFrom, 'YYYY-MM-DD'));
            }
            else {
                emit('identifier', identifier.value);
            }
        }, { immediate: true });
        const onMonthDayClick = () => {
            // When a day is clicked in a Month View, the calendar mode for the space page must be set to `week`
            // See parent `CalendarBlock.vue`
            emit('month-day-clicked');
        };
        const onMonthChange = (val) => {
            if (val) {
                emit('monthChange', val.now);
                identifier.value = val.now.full;
            }
        };
        const selectedDayNum = (val) => {
            emit('dayNum', val);
        };
        //Selected Date Object - month mode props { year, title }, week mode {year, title }
        const renderHeader = ({ prev, next, selectedDate, getSelectedDay }) => {
            const prevButton = createElement('div', {
                class: 'ui-calendar-control',
                attrs: {
                    role: 'button'
                },
                on: {
                    click: prev
                }
            }, [
                createElement('i', { class: 'fa-solid fa-arrow-left fa-xs arrows' }, [])
            ]);
            const nextButton = createElement('div', {
                class: 'ui-calendar-control',
                attrs: {
                    role: 'button'
                },
                on: {
                    click: next
                }
            }, [
                createElement('i', { class: 'fa-solid fa-arrow-right fa-xs arrows' }, [])
            ]);
            const dateText = createElement('div', { class: 'header-center' }, [
                createElement(popup, {
                    props: { info: selectedDate, currentDate: identifier.value },
                    on: {
                        'popup-change-month': function (date) {
                            // YYYY-MM-DD string
                            getSelectedDay(date);
                        }
                    }
                })
            ]);
            return createElement('div', { class: 'calendar-controls' }, [
                prevButton,
                dateText,
                nextButton
            ]);
        };
        return {
            getComponentMode,
            identifier,
            month,
            popup,
            transitionName,
            week,
            onMonthChange,
            onMonthDayClick,
            renderHeader,
            selectedDayNum
        };
    }
});
